"use client";

import type { Session } from "@supabase/auth-helpers-nextjs";
import { createContext, useEffect, useContext, useState, useMemo } from "react";
import type { TypedSupabaseClient } from "../layout";
import { createBrowserClient } from "#/lib/clients/supabase-browser";
import { useRouter } from "next/navigation";

export type MaybeSession = Session | null;

export const SupabaseContext = createContext<
  | {
      supabase: TypedSupabaseClient;
      session: MaybeSession;
    }
  | undefined
>(undefined);

export default function SupabaseProvider({
  children,
  session,
}: {
  children: React.ReactNode;
  session: MaybeSession;
}) {
  const [supabase] = useState(() => createBrowserClient());

  // FIXME: This is getting refreshed on every page focus
  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (process.env.NODE_ENV === "development") {
        console.log("[SupabaseProvider:onAuthStateChange]", event, session);
      }

      // The Supabase SDK will automatically parse the access token from the URL
      // when the user navigates back to the app & the browser client is instantiated.
      // Due to refresh token behavior, this function gets called every time the user
      // re-focuses on the page.
      // router.refresh();

      // console.log("Auth state changed:", { event, session });
      // // router.refresh(); // HEADS UP: not sure what the side effects of this are
      // if (event === 'SIGNED_IN') {
      //   window.location.replace(ORIGIN + "/");
      // } else if (event === 'SIGNED_OUT') {
      //   window.location.replace(ORIGIN + "/login");
      // } else {
      //   // Not sure...
      // }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [supabase]);

  const value = useMemo(() => ({ supabase, session }), [supabase, session]);

  return (
    <SupabaseContext.Provider value={value}>
      <>{children}</>
    </SupabaseContext.Provider>
  );
}
