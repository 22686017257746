"use client";

/**
 * Followed this guide for authentication:
 * https://supabase.com/docs/guides/auth/auth-helpers/nextjs-server-components
 */
import { FC, FormEvent, useState } from "react";
import { useSupabase } from "#/app/_hooks/useSupabase";
import { useSearchParams } from "next/navigation";
import Input from "#/app/_components/Forms/Input";
import SubmitButton from "#/app/_components/Forms/SubmitButton";
import Centered from "#/app/_components/Centered";
import toast from "react-hot-toast";
import { Spinner } from "#/app/_components/Spinner";
import Beta from "#/app/(auth)/Beta";
import { useRouter } from "next/navigation";
import { z, ZodSchema } from "zod";
import clsx from "clsx";
import { useAnalytics } from "#/app/_hooks/useSegment";

async function isValid<T>(schema: ZodSchema<T>, input: any): Promise<boolean> {
  const result = schema.safeParse(input);
  return result.success;
}

const LoginForm: FC = () => {
  const { supabase } = useSupabase();
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState<null | "done" | "submitting" | "error">(
    null
  );
  const [useMagicLink, setUseMagicLink] = useState(false);
  const analytics = useAnalytics();

  // Get YouTube URL from landing page form
  const searchParams = useSearchParams();
  const video_url = searchParams?.get("video_url");

  const handleGoogleAuth = async (event: any) => {
    event.preventDefault();

    try {
      analytics.track("Sign In Initiated", {
        provider: "google",
        query: {
          video_url,
        },
      });

      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: "google",
        options: {
          redirectTo: `${process.env.NEXT_PUBLIC_ORIGIN}/login/finish?video_url=${video_url}`,
        },
      });

      if (error) throw error;
    } catch (e) {
      console.log("error", e);
    }
  };

  const handleLoginOtp = async (event: any) => {
    event.preventDefault();

    const valid = await isValid(z.string().email().nonempty(), email);
    if (!valid) {
      toast.error("Please enter a valid email address");
      return;
    }

    setStatus("submitting");

    try {
      analytics.track("Sign In Form Submitted", {
        provider: "otp",
        email: email.toLowerCase(),
        query: {
          video_url,
        },
      });

      const { error } = await supabase.auth.signInWithOtp({
        email: email.toLowerCase(),
        options: {
          // FIXME: this isn't working locally
          emailRedirectTo: `${process.env.NEXT_PUBLIC_ORIGIN}/login/finish?video_url=${video_url}`,
          shouldCreateUser: true,
        },
      });

      if (error) throw error;
      setStatus("done");
    } catch (e) {
      setStatus("error");
    }
  };

  const handleEnterEmail = (event: FormEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };

  return (
    <div className="w-full">
      <div className="flex flex-col space-y-6">
        {status === "done" ? (
          <div className="rounded-xl bg-green-50 border border-green-200 p-6 text-center">
            <h3 className="text-xl font-medium mb-2 text-gray-900">
              Check your email
            </h3>
            <p className="text-gray-600">
              We&apos;ve sent a login link to{" "}
              <strong className="text-gray-900">{email}</strong>
            </p>
          </div>
        ) : status === "error" ? (
          <Beta />
        ) : (
          <>
            <div className="text-center">
              <h1 className="text-3xl font-bold text-gray-900">Welcome back</h1>
              <p className="text-gray-600 mt-2">
                Sign in to continue to ClipFM
              </p>
            </div>

            {useMagicLink ? (
              <form
                onSubmit={handleLoginOtp}
                className="flex flex-col space-y-4"
              >
                <Input
                  onChange={handleEnterEmail}
                  placeholder="Enter your email"
                  name="email"
                  type="email"
                  className="h-12 bg-white border-gray-200 text-gray-900 placeholder:text-gray-400 focus:border-[#4ECDC4] focus:ring-[#4ECDC4]/20"
                  autoFocus
                />
                <SubmitButton
                  type="submit"
                  className="h-12 bg-gradient-to-r from-[#FF6B6B] to-[#45B7D1] hover:opacity-90 text-white transition-all"
                  noColor={status === "submitting"}
                  disabled={status === "submitting"}
                >
                  {status === "submitting" ? (
                    <Spinner size={5} />
                  ) : (
                    "Continue with Email"
                  )}
                </SubmitButton>
              </form>
            ) : (
              <button
                onClick={handleGoogleAuth}
                className="flex h-12 items-center justify-center space-x-3 rounded-lg border border-gray-200 bg-white hover:bg-gray-50 text-gray-700 font-medium transition-colors"
              >
                <img
                  src="/images/google.png"
                  className="h-5 w-5"
                  alt="Google Logo"
                />
                <span>Continue with Google</span>
              </button>
            )}

            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-200"></div>
              </div>
              <div className="relative flex justify-center text-xs uppercase">
                <span className="bg-white/80 px-2 text-gray-500">Or</span>
              </div>
            </div>

            <div className="text-center">
              <button
                onClick={() => setUseMagicLink(!useMagicLink)}
                className="text-sm text-[#4ECDC4] hover:text-[#45B7D1] hover:underline"
              >
                {useMagicLink
                  ? "Sign in with Google instead"
                  : "Continue with email instead"}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LoginForm;

// const handleLoginOAuth = async () => {
//   const {data, error} = await supabase.auth.signInWithOAuth({
//     provider: 'google',
//   });
//   router.refresh();
//   if (error) {
//     console.log({error})
//   }
// }

/* <p>or</p>
<div>
  <SubmitButton onClick={handleLoginOAuth} noColor>
    <Image src={googleIcon} alt="google logo" width={23}/>&nbsp;&nbsp;Sign in with Google
  </SubmitButton>
  <div className="text-sm mt-2 text-gray-500">
    Need an account? <Link className="text-blue-500" href="/signup">Sign up</Link> instead.
  </div>
</div> */
