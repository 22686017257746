"use client";

import clsx from "clsx";
import { DetailedHTMLProps, FC, InputHTMLAttributes } from "react";

type InputProps = {
  error?: string | null;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const Input: FC<InputProps> = ({ error, className, ...props }) => {
  return (
    <>
      <input
        className={clsx(
          "bg-background focus:bg-background appearance-none border border-input rounded-xl w-full py-4 px-4 text-foreground leading-tight focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-0",
          className
        )}
        {...props}
      />
      {error && (
        <p className="ml-2 mt-1 text-left text-sm text-destructive">{error}</p>
      )}
    </>
  );
};

export default Input;
